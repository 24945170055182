import fitty from "fitty"
import randomcolor from "randomcolor"

const displayRandomApp = () => {
  const apps = document.querySelectorAll('div.cs-random-app');
  const randomAppIndex = Math.round(Math.random()*(apps.length-1));
  const selectedApp = apps[randomAppIndex];

  const shortcuts = selectedApp.getElementsByClassName('cs-random-keyboard-shortcut');
  const randomShortcutIndex = Math.round(Math.random()*(shortcuts.length-1));
  const selectedShortcut = shortcuts[randomShortcutIndex];

  selectedApp.style.display = 'block';
  selectedApp.className += " activeApp";

  selectedShortcut.style.display = 'block';
  selectedShortcut.className += " activeShortcut";

  const gradientBoxes = document.querySelectorAll('.cs-random-gradient-box')
  const colors = randomcolor({
    count: 4,
    alpha: 0.1,
    format: "rgba",
    luminosity: "dark"
  });
  gradientBoxes.forEach((box, i) => {
    const positions = box.classList[1].split('-').filter(cl => cl !== 'cs').join(' ');
    const gradientBackgroundImageStyle = `radial-gradient(ellipse at ${ positions }, ${ colors[i] }, rgba(0,0,0,0) 70%)`;
    box.style.backgroundImage = gradientBackgroundImageStyle;
  })

  fitty('.fit > div.shortcut', {
    maxSize: 80
  });
  fitty('.app-title', {
    maxSize: 120
  });
}

export default displayRandomApp