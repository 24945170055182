import React, { Component } from "react"
import Shortcut from "../components/shortcut"

import displayRandomApp from "../js/display-random-app.js"
import randomShortcutsLogo from "../images/random-shortcuts-logo.svg"
import "../css/random.scss"

const App = ({ app, logo, shortcutGroups, showGroupTitle }) => {
  const shortcuts = shortcutGroups.map(group => {
    const groupTitle = showGroupTitle ? group.title : '';
    return group.shortcuts.map((shortcut, i) => {
      return (
        <div key={i} className="cs-random-keyboard-shortcut">
          <div className="fit">
            <div className="shortcut">
              <Shortcut shortcut={ shortcut } />
            </div>
          </div>
          <div className="fit">
            <div className="uk-margin-small command">{ shortcut.command }</div>
            <div className="uk-margin-small cs-random-group-title">{ groupTitle }</div>
          </div>
        </div>
      )
    })
  })

  const Logo = () => {
    return (
      <div className="cs-random-app-logo uk-flex-inline uk-flex-middle">
        <img src={ logo } width="60" height="60" alt={ app.name } />
      </div>
    )
  }

  return (
    <div style={{zIndex: "2"}} className="cs-random-app" data-keyname={ app.keyname } data-twitter-handle={ app.twitterHandle } data-app-name={ app.name }>
      <h3 className="cs-random-app-title uk-flex-inline uk-flex-middle">
        <div>A random keyboard shortcut for</div> <Logo /> <div><strong>{ app.name }</strong></div>
      </h3>
      { shortcuts }
    </div>
  )
}

class RandomShortcuts extends Component {
  componentDidMount() {
    displayRandomApp()
  }
  render() {
    return (
      <div style={{height: "100%"}} className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-text-center" uk-height-viewport="true">
        { this.props.apps }
        <div className="cmd-space-follow uk-padding-small">
          <div className="cmd-space-logo">
            <img src={ randomShortcutsLogo } width="140" height="71" alt="Random Shortcuts Logo" />
          </div>
          <div className="cmd-space-follow-cta">
            visit <strong>randomshortcuts.com</strong>
          </div>
        </div>
      </div>
    )
  }
}

export default ({ pageContext: { appShortcutGroupsDict, allAppIndexJson, logoPublicURLs } }) => {

  const apps = allAppIndexJson.nodes.map((app, i) => {
    const logo = logoPublicURLs[app.keyname];
    const shortcutGroups = appShortcutGroupsDict[app.keyname]
    return <App key={i} app={app} logo={logo} shortcutGroups={shortcutGroups} showGroupTitle={app.showGroupsOnRandomViews} />
  })

  const GradientBox = ({ position }) => {
    const className = `cs-random-gradient-box cs-${position}`
    return (
      <div className={className}></div>
    )
  }

  return (
    <div className="cs-random">
      <RandomShortcuts apps={apps} />
      <GradientBox position="top-left" />
      <GradientBox position="top-right" />
      <GradientBox position="bottom-right" />
      <GradientBox position="bottom-left" />
    </div>
  )
}
